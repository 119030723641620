import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        maxWidth: "500px", // Increased width
        width: "100%",
        borderRadius: "8px",
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(3),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
}));

const AuthorityManagement = ({ value, setValue, handlePlatformFee, open, handleClose, loading }) => {

    return (
        <div>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Change Platform Fee
                </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description">
                        <input
                            style={{
                                width: "100%",
                                padding: "10px",
                                fontSize: "16px",
                                fontWeight: "bold",
                            }}
                            placeholder="Set Platform Fee"
                            className="form-control border border-secondary"
                            type="number"
                            min="0"
                            max="99"
                            onChange={(e) => {
                                let val = e.target.value;
                                // Restrict value between 0 and 99
                                if (val === "") {
                                    setValue(""); // Allow empty input
                                } else {
                                    val = Math.max(0, Math.min(99, Number(val))); // Clamp value within 0-99
                                    setValue(val);
                                }
                            }}
                            onPaste={(e) => e.preventDefault()} // Disable paste
                            onDrop={(e) => e.preventDefault()} // Disable drag and drop
                            value={value}
                        />



                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={() => handlePlatformFee({ value: value })}
                        autoFocus
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={15} sx={{ color: 'white' }} /> :
                            'Submit'}
                    </button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
};

export default AuthorityManagement;
