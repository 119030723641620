import React, { useEffect, useState } from "react";
import { Badge, Card, Col, Dropdown, Table } from "react-bootstrap";
import Pagination from "../components/Common/Pagination";
import PageTitle from "../layouts/PageTitle";
import toast from "react-hot-toast";
import { actionOnPendingPayments, pendingPaymentsBooking, setPlatformFee } from "../../services/User/UserService";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Common/Spinner";
import Filter from "../components/Filter";
import PaymentDetails from "./PaymentDetails";
import AuthorityManagement from "../components/Modal/AuthorityManagement";
import { Button } from "@mui/material";
import PendingPaymentAction from "../components/Modal/PendingPaymentAction";
import EditIcon from '@mui/icons-material/Edit';
import ConfirmModal from "../components/Modal/ConfirmModal";






export default function PendingPaymentManagement() {
    const [loader, setLoader] = useState(false);
    const [paymentList, setPaymentList] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [showModal, setShowModal] = useState({ show: false, data: '' });
    const [value, setValue] = useState()




    localStorage.setItem('activeIndex', 'Pending Payment Management')



    const limit = 10;
    const navigate = useNavigate();

    const getTableData = async () => {
        setLoader(true);
        try {
            const response = await pendingPaymentsBooking(currentPage, limit);
            const total = response.data.data.total;
            setPageCount(Math.ceil(total / limit));
            setPaymentList(response.data.data);

        } catch (error) {
            toast.error(error?.response?.data?.data);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        getTableData();
    }, [currentPage]);


    //changing/setting platform fee

    const [open, setOpen] = React.useState(false);



    //changing/setting platform fee
    const [filterType, setFilterType] = useState("payout");


    const FilterOption = [
        { label: "Payout", value: "payout" },
        { label: "Refund", value: "refund" },

    ];


    const [openPendingAction, setOpenPendingAction] = React.useState({ show: false, data: '' });
    const [confirmModal, setConfirmModal] = React.useState({ show: false, data: '', value });


    //confirm modal
    const handleOpenConfirm = (item, value) => {
        setConfirmModal({ show: true, data: item, value });
    };

    const handleCloseConfirm = () => {

        setConfirmModal({ show: false, data: '' });
    };

    const handleClickOpenPendingAction = (item) => {
        setOpenPendingAction({ show: true, data: item });
    };

    const handleClosePendingAction = () => {

        setOpenPendingAction({ show: false, data: '' });
    };
    const handlePendingAction = async (data) => {
        await actionOnPendingPayments({ bookingId: data?._id, type: data.type }).then(() => {
            toast.success('Successfull')
            handleCloseConfirm()
            handleClosePendingAction()
            getTableData()
        })

    }


    return (
        <div>
            <PageTitle activeMenu="History" motherMenu="Pending Payment Management" />
            <div className="pb-4 d-flex justify-content-end">
                {/* <Button class="d-flex justify-content-start text-secondary border border-secondary btn btn-sm" variant="outlined" onClick={handleClickOpen}>
                    Change Platform Fee
                </Button> */}

                <ConfirmModal title="" handleTask={handlePendingAction} open={confirmModal} handleClose={handleCloseConfirm} />

                <PendingPaymentAction
                    handleOpenConfirm={handleOpenConfirm}
                    open={openPendingAction}
                    FilterOption={FilterOption}
                    value={filterType}
                    setValue={setFilterType}
                    handlePaymentFee={handlePendingAction}
                    handleClose={handleClosePendingAction}
                    handleClickOpen={handleClickOpenPendingAction} />
                {/* <AuthorityManagement setValue={setValue} value={value} handlePlatformFee={handlePlatformFee} handleClickOpen={handleClickOpen} handleClose={handleClose} open={open} /> */}
            </div>
            {showModal?.show && <PaymentDetails setShowModal={setShowModal} showModal={showModal} />}
            <Col>
                <Card>
                    <Card.Body>
                        {loader ? (
                            <Spinner />
                        ) : (
                            <Table responsive className="mostly-customized-scrollba">
                                <thead style={{ color: "black" }}>
                                    <tr>
                                        <th>
                                            <strong>Booking Id</strong>
                                        </th>
                                        <th className="text-center">
                                            <strong>Course</strong>
                                        </th>
                                        <th className="text-center">
                                            <strong>Payout Amount</strong>
                                        </th>
                                        <th className="text-center">
                                            <strong>Amount</strong>
                                        </th>
                                        <th className="text-center">
                                            <strong>Platform Fee</strong>
                                        </th>

                                        <th className="text-center">
                                            <strong>Mentor Service Fee</strong>
                                        </th>
                                        <th className="text-center">
                                            <strong>Service Fee</strong>
                                        </th>
                                        <th className="text-center">
                                            <strong>Total Amount</strong>
                                        </th>


                                        <th className="text-center">
                                            <strong>Mentee Name</strong>
                                        </th>
                                        <th className="text-center">
                                            <strong>Mentor Name </strong>
                                        </th>

                                        <th className="text-center">
                                            <strong> STATUS</strong>
                                        </th>
                                        <th className="text-center">
                                            <strong>ACTION</strong>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paymentList?.bookings?.map((item, i) => (
                                        <tr key={i}>
                                            <td>{item?._id}</td>
                                            <td className="text-center">
                                                {item?.course?.subjectName}
                                                {/* {moment(item?.confirmSlot?.startTime).format("LT")}-
                        {moment(item?.confirmSlot?.endTime).format("LT")} */}
                                            </td>
                                            <td className="text-center">${item?.payment[0]?.payoutAmount}</td>
                                            <td className="text-center">${item?.payment[0]?.amount}</td>
                                            <td className="text-center">
                                                ${item?.platformFee}
                                            </td>
                                            <td className="text-center">${item?.payment[0]?.mentorServiceFee}</td>
                                            <td className="text-center">${item?.payment[0]?.serviceFee}</td>
                                            <td className="text-center">${item?.payment[0]?.totalAmount}</td>


                                            <td className="text-center">{item?.mentee?.firstName + "  " + item?.mentee?.lastName}</td>
                                            <td className="text-center">{item?.mentor?.firstName + "  " + item?.mentor?.lastName}</td>
                                            <td className="text-center">

                                                {/* <Badge bg="success">{item?.status}</Badge> */}
                                                {["Accepted", "Completed"]?.includes(item?.status) ? (
                                                    <Badge bg="success">{item?.status}</Badge>
                                                ) : ["Rejected", "Cancelled"]?.includes(item?.status) ? (
                                                    <Badge bg="danger light">{item?.status}</Badge>
                                                ) : (
                                                    <Badge bg="info light">{item?.status}</Badge>
                                                )}
                                            </td>
                                            <td className="text-center">
                                                <div
                                                    className="btn btn-xs sharp"
                                                    style={{ backgroundColor: "#2f4cdd", color: "#fff" }}
                                                    onClick={() =>
                                                        handleClickOpenPendingAction(item)
                                                        // setShowModal({ show: true, data: item })
                                                        // navigate("/payment-details", {state: item })
                                                    }
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </div>
                                            </td>

                                            {/* <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="warning light"
                            className="light sharp btn btn-warning i-false"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {item.isBlocked ? (
                              <Dropdown.Item onClick={() => onAction(item._id)}>
                                Enable
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item onClick={() => onAction(item._id)}>
                                Disable
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                        {paymentList?.bookings?.length === 0 && !loader && (
                            <div className="justify-content-center d-flex my-5 ">
                                Sorry, Data Not Found!
                            </div>
                        )}
                        {paymentList?.bookings?.length === 0 ? (
                            ""
                        ) : (
                            <div className="">
                                <Pagination
                                    limit={limit}
                                    listLength={paymentList?.total}
                                    pageCount={pageCount}
                                    pageValue={currentPage}
                                    setPage={setCurrentPage}
                                />
                            </div>
                        )}
                    </Card.Body>
                </Card>
            </Col>
        </div >
    );
}
