import React from "react";

export default function Filter({ FilterOption, setFilterType, filterType, setCurrentPage }) {
  return (
    <div>
      <div>
        <select
          style={{ textAlign: "start", fontWeight: "bold" }}
          className="d-flex justify-content-start text-secondary border border-secondary btn btn-sm"
          onChange={(e) => {

            setCurrentPage(0)
            setFilterType(e.target.value);
          }}
          value={filterType}
        >
          {FilterOption.map((item, i) => (
            <option key={i} className="filter-options" value={item.value} >
              {item.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
