import React, { useState } from "react";
import ReactPaginate from "react-paginate";

export default function Pagination({
  limit,
  listLength,
  pageCount,
  pageValue,
  setPage,
}) {
  function newFunction(data) {
    setPage(data);
  }
  return (
    <>
      {pageCount > 1 && (
        <div className="">
          <div className="d-flex justify-content-between align-items-center">
            <div className="dataTables_info">
              Showing {pageValue * limit + 1} to{" "}
              {listLength > (pageValue + 1) * limit
                ? (pageValue + 1) * limit
                : listLength}{" "}
              of {listLength} entries
            </div>
            <ReactPaginate
              pageCount={pageCount}
              forcePage={pageValue}
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"....."}
              marginPagesDisplayed={2}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"page-item active"}
              onPageChange={(selected) => {
                newFunction(selected.selected);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
