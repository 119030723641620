import React, { useEffect } from "react";
import ProfileCard from "../components/Bookings/ProfileCard";
import BookingInfo from "../components/Bookings/BookingInfo";
import { useLocation } from "react-router-dom";





function BookingDetails() {
  const location = useLocation();

  const data = location.state;



  localStorage.setItem('activeIndex', 'Bookings Management')



  return (
    <div className="booking-page">
      <div className="left-panel">
        <ProfileCard data={data?.mentee} title={"Mentee"} />
        <ProfileCard data={data?.mentor} title="Mentor" />
      </div>
      <div className="right-panel">
        <BookingInfo data={data} />
      </div>
    </div>
  );
}

export default BookingDetails;
