import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import defaultImage from "../../images/profile/user-dummy.png";
// import { KEYCHAIN } from '../utils/keychain';

const S3Image = ({ imageKey, style }) => {
  const [imageData, setImageData] = useState(null);

  const onImageError = (e) => {
    e.target.src = defaultImage;
  };




  useEffect(() => {
    const fetchImageData = async () => {
      if (!imageKey) {
        return;
      }
      try {
        const s3 = new AWS.S3();
        const params = {
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Key: imageKey,
        };
        const data = await s3.getObject(params).promise();
        const base64Image = data.Body.toString("base64");

        setImageData(base64Image);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImageData();
  }, [imageKey]);

  if (!imageData) {
    return (
      <div>
        <img height={60} width={60} src={defaultImage} className={style} />
      </div>
    );
  }

  return (
    <div>
      <img
        height={60}
        width={60}
        className={style}
        src={`data:image/png;base64,${imageData}`}
        alt="S3 Content"
        onError={onImageError}
      />
    </div>
  );
};

export default S3Image;
