import React, { useEffect, useState } from "react";
import { Badge, Card, Col, Dropdown, Table } from "react-bootstrap";
import Pagination from "../components/Common/Pagination";
import PageTitle from "../layouts/PageTitle";
import toast from "react-hot-toast";
import { actionOnPendingPayments, getPaymentsApi, setPlatformFee } from "../../services/User/UserService";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Common/Spinner";
import Filter from "../components/Filter";
import PaymentDetails from "./PaymentDetails";
import AuthorityManagement from "../components/Modal/AuthorityManagement";
import { Button } from "@mui/material";
import PendingPaymentAction from "../components/Modal/PendingPaymentAction";
import EditIcon from '@mui/icons-material/Edit';





export default function PaymentManagement() {
  const [loader, setLoader] = useState(false);
  const [paymentList, setPaymentList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [showModal, setShowModal] = useState({ show: false, data: '' });
  const [value, setValue] = useState()



  localStorage.setItem('activeIndex', 'Payment Management')




  const limit = 10;
  const navigate = useNavigate();

  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getPaymentsApi(currentPage, limit);
      const total = response.data.data.total;
      setPageCount(Math.ceil(total / limit));
      setPaymentList(response.data.data);
    } catch (error) {
      toast.error(error?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [currentPage]);


  //changing/setting platform fee

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const percentageFee = localStorage.getItem('percentageFee')
    setValue(percentageFee)
  }, [open])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handlePlatformFee = async (data) => {
    if (data?.value > -1 && data?.value !== '') {
      setLoading(true)
      await setPlatformFee({ percentageFee: data?.value }).then(() => {
        localStorage.setItem('percentageFee', data?.value)
        toast.success('Platform fee updated..')
        setLoading(false)
        handleClose()
      })
    } else {
      toast.error('Choose a value between 0-99')
    }

  }





  function formatString(str) {
    return str
      .replace(/_/g, ' ')  // Replace underscores with spaces
      .replace(/\b\w/g, char => char.toUpperCase());  // Capitalize the first letter of each word
  }



  return (
    <div>
      <PageTitle activeMenu="History" motherMenu="Payment Management" />
      <div className="pb-4 d-flex justify-content-end">
        <Button className="d-flex justify-content-start text-secondary border border-secondary btn btn-sm cursor-pointer" variant="outlined" onClick={handleClickOpen}>
          Change Platform Fee
        </Button>

        {/* <PendingPaymentAction open={openPendingAction} FilterOption={FilterOption} value={filterType} setValue={setFilterType} handlePaymentFee={handlePendingAction} handleClose={handleClosePendingAction} handleClickOpen={handleClickOpenPendingAction} /> */}
        <AuthorityManagement loading={loading} setValue={setValue} value={value} handlePlatformFee={handlePlatformFee} handleClickOpen={handleClickOpen} handleClose={handleClose} open={open} />
      </div>
      {showModal?.show && <PaymentDetails setShowModal={setShowModal} showModal={showModal} />}
      <Col>
        <Card>
          <Card.Body>
            {loader ? (
              <Spinner />
            ) : (
              <Table responsive className="mostly-customized-scrollba">
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>Payment Id</strong>
                    </th>
                    <th className="text-center">
                      <strong>Course</strong>
                    </th>
                    <th className="text-center">
                      <strong>Payout Amount</strong>
                    </th>
                    <th className="text-center">
                      <strong>Amount</strong>
                    </th>
                    <th className="text-center">
                      <strong>Mentor Service Fee</strong>
                    </th>
                    <th className="text-center">
                      <strong>Service Fee</strong>
                    </th>
                    <th className="text-center">
                      <strong>Total Amount</strong>
                    </th>

                    <th className="text-center">
                      <strong>Type</strong>
                    </th>

                    <th className="text-center">
                      <strong>Mentee Name</strong>
                    </th>
                    <th className="text-center">
                      <strong>Mentor Name </strong>
                    </th>

                    <th className="text-center">
                      <strong> STATUS</strong>
                    </th>
                    <th className="text-center">
                      <strong>ACTION</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paymentList?.payments?.map((item, i) => (
                    <tr key={i}>
                      <td>{item?.paymentId}</td>
                      <td className="text-center">
                        {item?.course?.subjectName}
                        {/* {moment(item?.confirmSlot?.startTime).format("LT")}-
                        {moment(item?.confirmSlot?.endTime).format("LT")} */}
                      </td>
                      <td className="text-center">${item?.payoutAmount}</td>
                      <td className="text-center">${item?.amount}</td>
                      <td className="text-center">${item?.mentorServiceFee}</td>
                      <td className="text-center">${item?.serviceFee}</td>
                      <td className="text-center">${item?.totalAmount}</td>
                      <td className="text-center">{formatString(item?.type)}</td>

                      <td className="text-center">{item?.mentee?.firstName + "  " + item?.mentee?.lastName}</td>
                      <td className="text-center">{item?.mentor?.firstName + "  " + item?.mentor?.lastName}</td>
                      <td className="text-center">
                        {item.status === "Confirm" ? (
                          <Badge bg="success">Completed</Badge>
                        ) : item.status === "Cancelled" ? (
                          <Badge bg="danger light">Cancelled</Badge>
                        ) : (
                          <Badge bg="info light">Awaiting</Badge>
                        )}
                      </td>
                      <td className="text-center">
                        <div
                          className="btn btn-xs sharp"
                          style={{ backgroundColor: "#2f4cdd", color: "#fff" }}
                          onClick={() =>
                            setShowModal({ show: true, data: item })
                            // navigate("/payment-details", {state: item })
                          }
                        >
                          <i className="fa fa-eye"></i>
                        </div>
                      </td>

                      {/* <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="warning light"
                            className="light sharp btn btn-warning i-false"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {item.isBlocked ? (
                              <Dropdown.Item onClick={() => onAction(item._id)}>
                                Enable
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item onClick={() => onAction(item._id)}>
                                Disable
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {paymentList?.payments?.length === 0 && !loader && (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            )}
            {paymentList?.payments?.length === 0 ? (
              ""
            ) : (
              <div className="">
                <Pagination
                  limit={limit}
                  listLength={paymentList?.total}
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </div >
  );
}
