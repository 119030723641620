export const USERLIST = "admin/mentorList";

export const USER_ACTION = "admin/userAction";

export const USERLIST_MENTEE = "admin/menteList";

export const FAQS = "admin/faqs";

export const CREATE_FAQS = "admin/createFaq";

export const FAQ_EDIT = "admin/editFaq";

export const FAQ_DELETE = "admin/deleteFaq";

export const PLATFORM_FEE = "admin/setPlatformFee";

export const PENDING_PAYMENT = "admin/actionOnPendingPayments";


export const GET_PENDING_PAYMENTS_BOOKING = "admin/pendingPaymentsBooking";