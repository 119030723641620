import React, { useEffect, useState } from "react";

const PaymentDetails = ({ showModal, setShowModal }) => {
    const [formData, setFormData] = useState({ ...showModal?.data });

    useEffect(() => {
        if (showModal) {
            setFormData({
                mentor: showModal?.data?.mentor,
                mentee: showModal?.data?.mentee,
                booking_Id: showModal?.data?.booking?._id || "N/A",
                course: showModal?.data?.course?.subjectName || "N/A",
                serviceFee: showModal?.data?.serviceFee || "N/A",
                amount: showModal?.data?.amount || "N/A",
                payoutAmount: showModal?.data?.payoutAmount,
                totalAmount: showModal?.data?.totalAmount || "N/A",
                type: showModal?.data?.type || "N/A",
                status: showModal?.data?.status || "N/A",
                payment_Id: showModal?.data?.paymentId || "N/A",
            });
        }
    }, [showModal]);

    // Disable scrolling when the modal is open
    useEffect(() => {
        if (showModal?.show) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

        return () => {
            document.body.style.overflow = "auto"; // Cleanup on unmount
        };
    }, [showModal]);

    function formatString(str) {
        return str
            ?.replace(/_/g, " ") // Replace underscores with spaces
            ?.replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
    }


    function formatKey(key) {
        return key
            ?.replace(/_/g, " ") // Replace underscores with spaces
            ?.replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize the first letter of each word
            ?.trim(); // Ensure no trailing spaces
    }
    return (
        <div
            className="modal fade show d-flex align-items-center justify-content-center"
            tabIndex="-1"
            role="dialog"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", padding: "10px", pointerEvents: "auto" }}
            onClick={() => setShowModal({ show: false, data: "" })}
        >
            <div
                className="modal-dialog modal-dialog-centered w-100"
                style={{ maxWidth: "500px", minWidth: "320px" }}
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
            >
                <div className="modal-content p-3 rounded-4 shadow-lg bg-white">
                    {/* Header */}
                    <div className="modal-header border-0">
                        <h5 className="modal-title fw-bold fs-5">Payment Details</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => setShowModal({ show: false, data: "" })}
                        ></button>
                    </div>

                    {/* Body */}
                    <div className="modal-body">
                        <div className="overflow-auto" style={{ maxHeight: "60vh" }}>
                            <table className="table table-hover align-middle">
                                <tbody>
                                    {Object.entries(formData).map(([key, value]) => (
                                        <tr key={key} className="border-bottom">
                                            <th className="text-muted text-capitalize w-40 fs-6 py-2 text-nowrap">
                                                {formatKey(key)}
                                            </th>
                                            <td className="text-dark fw-semibold fs-6 py-2">
                                                {["serviceFee", "amount", "payoutAmount", "totalAmount"].includes(key) &&
                                                    value !== "N/A"
                                                    ? `$${value}`
                                                    : key === "type"
                                                        ? formatString(value)
                                                        : typeof value === "object" && value !== null
                                                            ? `${value.firstName || ""} ${value.lastName || ""} (${value.email || "No Email"})`
                                                            : value}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentDetails;
