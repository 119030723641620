import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        maxWidth: "500px", // Increased width
        width: "100%",
        borderRadius: "8px",
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(3),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
    },
}));

const PendingPaymentAction = ({ value, setValue, FilterOption, handlePaymentFee, open, handleClose, handleOpenConfirm }) => {


    return (
        <div>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open?.show}>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Pending Payment Action
                </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent dividers>
                    <Typography gutterBottom sx={{
                        wordWrap: 'break-word',
                        whiteSpace: 'normal',
                    }}><strong>Mentee Mark :- </strong>{open?.data?.bookingStatus}</Typography>
                    <DialogContentText id="alert-dialog-description">
                        {/* <input
                            style={{
                                width: "100%",
                                padding: "10px",
                                fontSize: "16px",
                                fontWeight: "bold",
                            }}
                            placeholder="Pending Payment Action"
                            className="form-control border border-secondary"
                            type="number"
                            onChange={(e) => setValue(e.target.value)}
                            value={value}
                        /> */}
                        <select
                            style={{ textAlign: "start", fontWeight: "bold" }}
                            className="d-flex justify-content-start text-secondary border border-secondary btn btn-sm w-100"
                            onChange={(e) => {
                                setValue(e.target.value);

                            }}
                            value={value}
                        >
                            {FilterOption.map((item, index) => (
                                <option key={index} className="filter-options" value={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                            handleOpenConfirm(open?.data, value)
                            // handlePaymentFee({value: value })
                        }}
                        autoFocus
                    >
                        Submit
                    </button>
                </DialogActions>
            </BootstrapDialog>
        </div >
    );
};

export default PendingPaymentAction;
