import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Typography } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        maxWidth: "400px",
        width: "100%",
        borderRadius: "12px",
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(3),
        textAlign: "center",
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
        justifyContent: "center",
    },
}));

const ConfirmModal = ({ title, handleTask, open, handleClose }) => {
    return (
        <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open?.show} maxWidth="xs" fullWidth>
            <DialogTitle sx={{ m: 0, p: 2, textAlign: "center", color: "error.main", fontWeight: "bold" }}>
                <WarningAmberIcon sx={{ fontSize: 36, color: "error.main", mr: 1, verticalAlign: "middle" }} />
                {/* {title || "Confirm Action"} */}
            </DialogTitle>

            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent dividers>
                <DialogContentText id="alert-dialog-description" sx={{ fontSize: "1rem", fontWeight: 500 }}>
                    Are you sure you want to <strong>{open?.value}</strong>?
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => handleTask({ _id: open?.data?._id, type: open?.value })}
                >
                    Yes, Confirm
                </Button>
                <Button
                    variant="outlined"
                    color="inherit"
                    size="small"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
};

export default ConfirmModal;
