import axiosInstance from "../AxiosInstance";
import {
  CREATE_FAQS,
  FAQ_DELETE,
  FAQ_EDIT,
  FAQS,
  GET_PENDING_PAYMENTS_BOOKING,
  PENDING_PAYMENT,
  PLATFORM_FEE,
  USER_ACTION,
  USERLIST,
  USERLIST_MENTEE,
} from "./UserEndPoint";

export function getUserList(page, limit, search, startDate, endDate) {
  return axiosInstance.get(
    USERLIST +
    `?page=${page}&limit=${limit}&search=${search}&startDate=${startDate}&endDate=${endDate}`
  );
}
export function deleteUser(id) {
  return axiosInstance.delete(USERLIST + `?userId=${id}`);
}
export function blockUser(id) {
  const postData = { userId: id };
  return axiosInstance.put(USER_ACTION, postData);
}
export function getUserMentee(page, limit, search, startDate, endDate) {
  return axiosInstance.get(
    USERLIST_MENTEE +
    `?page=${page}&limit=${limit}&search=${search}&startDate=${startDate}&endDate=${endDate}`
  );
}
export function getFAQSList(page, limit) {
  return axiosInstance.get(FAQS + `?page=${page}&limit=${limit}`);
}

export function createFaq(formData) {
  return axiosInstance.post(CREATE_FAQS, formData);
}
export function editFaq(formData) {
  return axiosInstance.put(FAQ_EDIT, formData);
}

export function deleteFaq(id) {
  return axiosInstance.delete(FAQ_DELETE + `?id=${id}`);
}

//Bookings Api  =================================================================

export function getBookingsApi(page, limit, type) {
  return axiosInstance.get(
    "admin/bookings" + `?page=${page}&limit=${limit}&type=${type}`
  );
}


//Payments Api  =================================================================

export function getPaymentsApi(page, limit, type) {
  return axiosInstance.get(
    "admin/payments" + `?page=${page}&limit=${limit}`
  );
}


//Authority change ==============================================================
export function setPlatformFee(payload) {
  return axiosInstance.put(PLATFORM_FEE, payload);
}


//Pending payments change =======================================================
export function actionOnPendingPayments(payload) {
  return axiosInstance.post(PENDING_PAYMENT, payload);
}


//Pending payments bookings  =====================================================
export function pendingPaymentsBooking(page, limit, type) {
  return axiosInstance.get(
    GET_PENDING_PAYMENTS_BOOKING + `?page=${page}&limit=${limit}`
  );
}