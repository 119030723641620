import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col, Button } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";

import Pagination from "../components/Common/Pagination";
import {
  blockUser,
  getUserList,
  getUserMentee,
} from "../../services/User/UserService";
import { notifyError, notifyTopRight } from "../components/Common/Toaster";
import Spinner from "../components/Common/Spinner";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";





export default function MenteeManagement(props) {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [listLength, setListLength] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [initialFetch, setInitialFetch] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [pickerKey, setPickerKey] = useState(0);

  const limit = 10;




  localStorage.setItem('activeIndex', 'Mentee Management')


  const handleApply = (event, picker) => {

    setStartDate(picker.startDate.format("MM/DD/YYYY"));
    setEndDate(picker.endDate.format("MM/DD/YYYY"));
    setCurrentPage(0);
  };

  const handleCancel = (event, picker) => {
    setCurrentPage(0);
    setStartDate("");
    setEndDate("");
    setPickerKey(prevKey => prevKey + 1); // Update key to force re-render
    getTableData(); // Refetch table data without filters

  }


  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  const getTableData = async () => {
    setLoader(true);

    try {

      const response = await getUserMentee(
        currentPage,
        limit,
        search,
        startDate,
        endDate
      );
      setUsers(response.data.data.mentee);
      const total = response.data.data.total;
      setPageCount(Math.ceil(total / limit));
      setListLength(total);
      setLoader(false);
      setInitialFetch(false);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  };

  // function onDelete(id) {
  //   setLoader(true);
  //   deleteUser(id)
  //     .then((response) => {
  //       getTableData();

  //       setLoader(false);
  //     })
  //     .catch((error) => {
  //       console.log(error.response, "helooo");
  //       setLoader(false);

  //     });
  // }
  const onAction = async (id) => {
    setLoader(true);
    try {
      const response = await blockUser(id);
      notifyTopRight(response.data?.message);
      getTableData();
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error.response, "error");
      setLoader(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [currentPage, endDate]);
  let timeoutId;
  // Define a helper function for debouncing
  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const debouncedHandleFetch = debounce(getTableData, 500);
  useEffect(() => {
    if (initialFetch === false) {
      setCurrentPage(0);
      debouncedHandleFetch();
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [search]);

  const handleShow = () => {
    setTimeout(() => {
      const cancelButton = document.querySelector(".daterangepicker .cancelBtn");
      if (cancelButton) cancelButton.textContent = "Clear"; // Rename cancel button
    }, 10); // Small delay to ensure DOM updates
  }
  return (
    <div>
      <PageTitle activeMenu="Mentee List" motherMenu="Mentee Management" />
      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-center">
              <div className="col-4">
                <div
                  className="input-group border bg-white input-group-sm"
                  style={{ borderRadius: "8px" }}
                >
                  <input
                    style={{
                      paddingBottom: "25px",
                      paddingTop: "25px",
                      borderRadius: "10px",
                      fontSize: "14px",
                    }}
                    type="text"
                    name="table_search"
                    className="form-control float-right border-0"
                    placeholder="Search"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={getTableData}
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="example rangeDatePicker">
                <DateRangePicker
                  onApply={handleApply}
                  onCancel={handleCancel}
                  onShow={handleShow}>
                  <input
                    type="text"
                    className="form-control input-daterange-timepicker"
                    placeholder={"Filter by date"}
                    value={
                      (startDate && endDate) ? `${startDate} - ${endDate}` : ""
                    }
                    readOnly
                  />
                </DateRangePicker>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            {loader ? (
              <Spinner />
            ) : (
              <Table responsive className="mostly-customized-scrollba">
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>NAME</strong>
                    </th>
                    <th>
                      <strong>EMAIL</strong>
                    </th>
                    <th>
                      <strong>PHONE NUMBER</strong>
                    </th>
                    <th>
                      <strong> STATUS</strong>
                    </th>
                    <th>
                      <strong>ACTION</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((item, i) => (
                    <tr key={i}>
                      <td>
                        {item.firstName} {item.lastName}
                      </td>
                      <td>{item.email}</td>
                      <td>
                        {item?.countryCode} {item.phoneNumber}
                      </td>

                      <td>
                        {item.isBlocked ? (
                          <Badge bg="" className="badge-danger">
                            Disabled
                          </Badge>
                        ) : (
                          <Badge bg="" className="badge-success">
                            Enabled
                          </Badge>
                        )}
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="warning light"
                            className="light sharp btn btn-warning i-false"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {item.isBlocked ? (
                              <Dropdown.Item onClick={() => onAction(item._id)}>
                                Enable
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item onClick={() => onAction(item._id)}>
                                Disable
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {users?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            {users?.length === 0 ? (
              ""
            ) : (
              <div className="">
                <Pagination
                  limit={limit}
                  listLength={listLength}
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}
