import React from "react";
import "../../pages/BookingDetails.css";
import moment from "moment";
import { Badge } from "react-bootstrap";
import starIcon from "../../../images/star.png";

const BookingInfo = ({ data }) => {



  function formatString(str) {
    return str?.replace(/_/g, ' ')?.replace(/\b\w/g, char => char.toUpperCase());  // Capitalize the first letter of each word
  }
  return (
    <div className="booking-details">
      <div className="d-flex justify-content-between">
        <h3 className="mb-0">{data?.course?.subjectName}</h3>
        <p className="m-0">{moment(data?.createdAt).format("ll")}</p>
      </div>
      {data?.rating && <p className="text-black m-0 pb-1" style={{ fontSize: "14px" }}>
        Ratings: <span>{data?.rating || "N/A"}</span>
        {data?.rating && (
          <img
            style={{ paddingRight: "6px", marginLeft: "5px" }}
            src={starIcon}
            width={20}
          />
        )}
      </p>}
      {data.status === "Completed" ? (
        <Badge bg="success">Completed</Badge>
      ) : data.status === "Cancelled" ? (
        <Badge bg="danger light">Cancelled</Badge>
      ) : (
        <Badge bg="info light">Awaiting</Badge>
      )}

      <div className="details-section">
        <h4 className="font-w600">Course Details</h4>
        <div className="details">
          <p>
            <strong>Subject Name: </strong>
            {data?.course?.subjectName}
          </p>
          <p > <strong>Rating:</strong> <span>{data?.course?.rating ? data?.course?.rating : "N/A"}</span>
            {/* {data?.course?.rating && (
              <img
                style={{ paddingRight: "6px", marginLeft: "5px" }}
                src={starIcon}
                width={20}
              />
            )} */}
          </p>
          <p>
            <strong>Mentee:</strong>{" "}
            {data?.course?.menteeLearn?.map((item) => `${item},`)}
          </p>
          <p className="d-flex  flex-wrap align-items-center">
            <strong>Pricing:</strong>{" "} {Object.entries(data?.course?.pricing).map(([key, value]) => (<p style={{ marginLeft: '5px', marginRight: '5px' }}> <span style={{ color: 'red', fontSize: '16px', }}>${value}/</span>  {key?.split('_')[1]},</p>
            ))}
          </p>
          <p>
            <strong>Description:</strong> {data?.course?.description}
          </p>
        </div>
      </div>
      <div className="details-section">
        <h4 className="font-w600">Booking Details</h4>
        <div className="details">
          <p>
            <strong>Total Amount: </strong>${data.amount}
          </p>
          <p>
            <strong>Booking Date:</strong>{" "}
            {moment(data?.bookingDate).format("ll")}
          </p>
          <p>
            <strong>Slot:</strong>{" "}
            {moment(data?.confirmSlot?.startTime).format("LT")} -{" "}
            {moment(data?.confirmSlot?.endTime).format("LT")}
          </p>
          <p>
            <strong>Meet url:</strong>{" "}
            <a
              href={data?.meetUrl ? data?.meetUrl : ''}
              target="blank"
              style={{ textDecoration: "underline", color: "blue" }}
            >
              {data?.meetUrl || "N/A"}
            </a>
          </p>
        </div>
        {data?.payment?.length > 0 ? data?.payment?.map((item, index) => {
          return (<>  <div className="details-section" key={index}>
            <h4 className="font-w600 capitalize" style={{ textTransform: 'capitalize' }}>{index == 0 ? 'Payment' : item?.type} Details</h4>
            <div className="details">
              <p>
                <strong>Amount + Service Fee: </strong>${item?.amount} + ${item?.serviceFee}
              </p>
              <p>
                <strong>Payout amount:</strong> ${item?.payoutAmount}
              </p>
              <p>
                <strong>Type:</strong> {formatString(item?.type)}
              </p>
              <p>
                <strong>Status:</strong>{formatString(item?.type) == 'Mentor Payment' && item?.status}  {formatString(item?.type) !== 'Mentor Payment' && (item?.status == 'Confirm' && formatString(item?.type) == 'Refund' ? 'Refund Issued' : formatString(item?.type) == 'Payout' ? 'Payout Done' : formatString(item?.type))}
              </p>
            </div>
          </div>
          </>)
        }) : ''}

        {/* {data?.payment[1] && <div className="details-section">
          <h4 className="font-w600">Payment Details</h4>
          <div className="details">
            <p>
              <strong>Amount: </strong>${data.payment[1].amount}
            </p>
            <p>
              <strong>Payout amount:</strong> ${data.payment[1].payoutAmount}
            </p>
            <p>
              <strong>Type:</strong> {formatString(data?.payment[1]?.type)}
            </p>
            <p>
              <strong>Status:</strong> {data?.payment[1]?.status}
            </p>
          </div>
        </div>} */}
      </div>
    </div >
  );
};

export default BookingInfo;
